import { Component, OnInit, Renderer2, ViewChild, ElementRef, Input, ChangeDetectionStrategy,
         ChangeDetectorRef, AfterViewInit, AfterContentChecked } from '@angular/core';
import { ROUTES } from '../../sidebar/sidebar.component';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Location } from '@angular/common';
import { filter, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { MENU, NAV_ROLE_FILTER, DEFAULT_PAGE } from 'app/common/constants';
import { AuthUserService } from '../../core/services/auth-user.service';
import { ApiCacheService } from 'app/core/services/api-cache.service';

@Component({
  moduleId: module.id,
  selector: 'navbar-cmp',
  templateUrl: 'navbar.component.html',
  styles: [`
    .nav-item p {
      font-size: 14.5px;
      color: #000000;
    }

    .nav-item {
      cursor: pointer;
    }

    .active{
      color: #1645DB !important;
    }
  `]
})

export class NavbarComponent implements OnInit,AfterViewInit, AfterContentChecked {
  @Input() isClient;
  @Input() isClientPage;
  isAdmin: boolean = parseInt(localStorage.getItem('role')) == 1;
  isDataController: boolean = parseInt(localStorage.getItem('role')) == 2;
  activeIndex: number = -1;
  listTitles: any[];
  public pageTitle;
  location: Location;
  nativeElement: Node;
  toggleButton;
  path;
  menu;
  defaultPage;
  isHome;
  leftImage;
  leftImageHeight;
  showAdmin;
  customerName;
  reportMenu;
  reportPath;
  asDropdown = environment.navBar.asDropdown;
  highlightOptions = environment?.navBar?.highlightOptions;
  params = window.location.href.split('/')
  private sidebarVisible: boolean;

  public isCollapsed = true;
  @ViewChild("navbar-cmp", { static: false }) button;
  @ViewChild('dropdownButton') dropdownButton: ElementRef;
  @ViewChild('content') content: ElementRef;

  constructor(private cdref: ChangeDetectorRef, private activatedRoute: ActivatedRoute,
              location: Location, private renderer: Renderer2, private element: ElementRef,
              private router: Router, private authUserService: AuthUserService, private apiCacheService: ApiCacheService) {
    let role = parseInt(localStorage.getItem('role'));
    this.location = location;
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
    this.leftImage = `../../../../${environment.navBar.leftImage}`;
    this.leftImageHeight = environment.navBar.leftImageHeight;
    this.showAdmin = environment.navBar.showAdmin;
    this.menu = MENU.filter(m => {
      const roles = NAV_ROLE_FILTER[m.name];
      if (Array.isArray(roles) && roles.length > 0) {
        const roleSet = new Set(roles);
        return roleSet.has(role);
      }
      return true;
    });
    this.defaultPage = DEFAULT_PAGE;
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.dropdownButton && e.target !== this.dropdownButton.nativeElement && e.target !== this.content.nativeElement) {
        this.dropdownButton.nativeElement.setAttribute('class', 'dropdown-button');
      }
   });

    this.activeIndex = this.menu.findIndex(m => {
      if (m.defaultForRole === role) {
        return true;
      }
      return m.default && !m.defaultForRole;
    });
  }

  clientNameMenuClicked(option: any) {
    this.customerName = option.name;
    this.router.navigate(['/' + option.name]);
    if (option.pages.length > 1) {
      this.reportMenu = option.pages?.filter(p => !p.hide)
    } else {
      this.reportMenu = []
    }
  }
  handleReportClick(reportName) {
    this.reportPath = `${this.customerName}/${reportName}`
    this.router.navigate(['/' + this.reportPath])
  }

  ngOnInit() {
    this.customerName = this.activatedRoute.snapshot.firstChild.url[0].path;
    const rptMenu = MENU.find(m => m.name === this.customerName);
    if (rptMenu?.pages?.length > 1) {
      this.reportMenu = rptMenu.pages?.filter(p => !p.hide)
    } else {
      this.reportMenu = [];
    }
    this.listTitles = ROUTES.filter(listTitle => listTitle);
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar?.getElementsByClassName('navbar-toggle')[0];
    // this.router.events.subscribe((event) => {
    //   this.sidebarClose();
    // });
    this.getTitle();
    this.router
      .events.pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          const child = this.activatedRoute.firstChild;
          if (child.snapshot.data['title']) {
            return child.snapshot.data['title'];
          }
        })
      ).subscribe((ttl: string) => {
        this.pageTitle=ttl;
      });
  }

  navigateToPage(url: string) {
    url = url.includes('skipped') ? url.replace(' ', '-') : url;
    this.router.navigate([url]).then(r => null);
  }

  setActiveIndex(index: number) {
    this.activeIndex = index;
  }

  ngAfterContentChecked() {
    this.getPath();
    this.cdref.detectChanges();
  }

  ngAfterViewInit() {
    this.getPath();
  }

  getTitle() {
    if (!this.isClient) {
      var titlee = this.location.prepareExternalUrl(this.location.path());
      if (titlee.charAt(0) === '#') {
        titlee = titlee.slice(1);
      }
      for (var item = 0; item < this.listTitles.length; item++) {
        if (this.listTitles[item].path==titlee || titlee.includes(this.listTitles[item].path)) {
          this.pageTitle = this.listTitles[item].title;
          return this.pageTitle;
        }
      }
      this.pageTitle = 'Dashboard';
      return 'Dashboard';
    }
  }

  sidebarToggle() {
    if (this.sidebarVisible === false) {
      this.sidebarOpen();
    } else {
      this.sidebarClose();
    }
  }

  sidebarOpen() {
    const toggleButton = this.toggleButton;
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    setTimeout(function () {
      toggleButton?.classList?.add('toggled');
    }, 500);

    html.classList.add('nav-open');
    if (window.innerWidth < 991) {
      mainPanel.style.position = 'fixed';
    }
    this.sidebarVisible = true;
  };

  back(): void {
    this.location.back();
  }

  getPath(){
    this.path = this.location.path();
    this.isHome = (this.path=='/superdry' || this.path=='/twoeightone') ? true : false;
    return this.isHome ? 'Inspection Reports' : 'Home';
  }

  sidebarClose() {
    const html = document.getElementsByTagName('html')[0];
    const mainPanel = <HTMLElement>document.getElementsByClassName('main-panel')[0];
    if (window.innerWidth < 991) {
      setTimeout(function () {
        if(mainPanel)
        mainPanel.style.position = '';
      }, 500);
    }
    this.toggleButton?.classList?.remove('toggled');
    this.sidebarVisible = false;
    html.classList.remove('nav-open');
  };

  collapse() {
    this.isCollapsed = !this.isCollapsed;
    const navbar = document.getElementsByTagName('nav')[0];
    if (!this.isCollapsed) {
      if (!this.isClient) {
        navbar.classList.remove('navbar-transparent');
      }
      navbar.classList.add('bg-white');
    } else {
      if (!this.isClient) {
        navbar.classList.add('navbar-transparent');
      }
      navbar.classList.remove('bg-white');
    }
  }

  logout() {
    this.apiCacheService.clear()
    this.authUserService.logout();
  }

  openInspections() {
    this.router.navigate(['/twoeightone/inspection']);
  }
}
